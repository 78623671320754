import { useState, useEffect } from "react";

/**
 * Custom hook for fetching JSON data based on domain name.
 * @param {string} domain - The domain to match.
 * @param {string} jsonPath - The path to the JSON file.
 * @returns {object} - An object containing content, isLoading, and error states.
 */
function useDomainContent(domain) {
  const [commonContent, setCommonContent] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const jsonPath = process.env.REACT_APP_PAGE_ASSETS + "/jsons/common-mlo-landing-pages.json"

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      setError(null);

      try {
        const response = await fetch(jsonPath);

        if (!response.ok) {
          throw new Error(`Failed to fetch JSON: ${response.statusText}`);
        }

        const data = await response.json();

        // Check if content for the domain exists
        if (data[domain]) {
          setCommonContent(data[domain]);
        } else {
          setCommonContent(null); // Fallback for missing content
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    }

    if (domain) {
      fetchData();
    } else {
      setIsLoading(false);
      setCommonContent(null);
    }
  }, [domain, jsonPath]);

  return { commonContent, isLoading, error };
}

export default useDomainContent;
